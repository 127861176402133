import './App.css';
import axios from 'axios';
import RendezvousOnTheRed from './RendezvousOnTheRed/RendezvousOnTheRed';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useLocation } from 'react-router-dom';
import Invoice from './RendezvousOnTheRed/Invoice';

function App() {
  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://ninja.sportssouth.dev';
  }

  const location = useLocation();

  return location.pathname === '/invoice' &&
    /^\?access_code=[a-zA-Z0-9]{2}-[a-zA-Z0-9]{2}-[a-zA-Z0-9]{2}$/.test(location.search) ? (
    <Invoice />
  ) : (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RendezvousOnTheRed />
    </LocalizationProvider>
  );
}

export default App;
