import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PDFDocument from '../_GlobalComponents/PDFDocument';
import { pdf } from '@react-pdf/renderer';
import axios from 'axios';
import { Box, CircularProgress, Typography } from '@mui/material';

const Invoice = () => {
  const [searchParams] = useSearchParams();
  const accessCode = searchParams.get('access_code').toUpperCase();
  const [invoiceData, setInvoiceData] = useState(null);

  const getInvoiceData = useCallback(() => {
    axios
      .get('/rotr/invoiceData', { params: { accessCode: accessCode } })
      .then((response) => {
        setInvoiceData(response.data);
      })
      .catch((error) => {
        setInvoiceData('error');
      });
  }, [accessCode]);

  const handleDownload = useCallback(async (data) => {
    const blob = await pdf(<PDFDocument data={data} />).toBlob({
      type: 'application/pdf',
    });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }, []);

  useEffect(() => {
    getInvoiceData();
  }, [getInvoiceData]);

  useEffect(() => {
    if (invoiceData && invoiceData !== 'error' && invoiceData?.length > 0) {
      handleDownload(invoiceData);
    }
  }, [invoiceData, handleDownload]);

  return (
    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {invoiceData === null ? (
        <CircularProgress />
      ) : invoiceData === 'error' ? (
        <Typography variant='h5'>
          Error loading invoice data. Please refresh and try again, or contact an administrator if
          this continues.
        </Typography>
      ) : invoiceData?.length > 0 ? (
        <Typography variant='h5'>Invoice download initiated!</Typography>
      ) : (
        <Typography variant='h5'>No invoice available.</Typography>
      )}
    </Box>
  );
};

export default Invoice;
