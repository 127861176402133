import React from 'react';
import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../RendezvousOnTheRed/Assets/full_duck_left_blue.png';

Font.register({
  family: 'Helvetica',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/@fontsource/helvetica/files/helvetica-all-400-normal.woff',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/@fontsource/helvetica/files/helvetica-all-700-normal.woff',
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 50,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    alignItems: 'flex-start',
  },
  header2: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
    alignItems: 'flex-start',
  },
  logoContainer: {
    width: '35%',
    marginBottom: 20,
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  companyInfo: {
    flexDirection: 'column',
  },
  invoiceInfo: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  companyName: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  rendezvous: {
    fontSize: 12,
    fontStyle: 'italic',
    color: 'grey',
  },
  address: {
    fontSize: 12,
    marginBottom: 5,
  },
  invoiceTitle: {
    fontSize: 26,
    fontWeight: 'bold',
    color: 'grey',
    textAlign: 'right',
  },
  invoiceDetails: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: 12,
  },
  invoiceLabel: {
    fontWeight: 'bold',
  },
  customerInfo: {
    marginBottom: 20,
  },
  toLabel: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 5,
  },
  customerText: {
    fontSize: 12,
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 40,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#EEEEEE',
    fontWeight: 'bold',
    padding: 5,
    fontSize: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#CCCCCC',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#CCCCCC',
  },
  tableCell: {
    flex: 1,
    padding: 5,
    fontSize: 12,
  },
  totalLabel: {
    fontWeight: 'bold',
    fontSize: 12,
    padding: 5,
  },
  totalValue: {
    fontSize: 12,
    padding: 5,
    textAlign: 'right',
  },
  footer: {
    fontSize: 12,
    textAlign: 'center',
  },
  footerText: {
    textAlign: 'left',
    marginBottom: 5,
  },
  thankYou: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 20,
  },
});

const PDFDocument = ({ data = [] }) => {
  const convertValueToCurrency = (value) => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      return typeof value === 'number' ? '-' : value;
    }
    return numberValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  return (
    <Document>
      {data.map((exhibitor) => (
        <Page key={exhibitor.rotr_exhibitor_id} break size='A4' style={styles.page}>
          <View style={styles.logoContainer}>
            <Image src={logo} style={styles.logo} />
          </View>

          <View style={styles.header}>
            <View style={styles.companyInfo}>
              <Text style={styles.companyName}>Sports South</Text>
              <Text style={styles.rendezvous}>Rendezvous on the Red</Text>
            </View>
            <Text style={styles.invoiceTitle}>INVOICE</Text>
          </View>

          <View style={styles.header2}>
            <View style={styles.companyInfo}>
              <Text style={styles.address}>101 Robert G. Harris Dr.</Text>
              <Text style={styles.address}>Shreveport LA, 71115</Text>
              <Text style={styles.address}>Phone: (800) 388-3845</Text>
            </View>
            <View style={styles.invoiceInfo}>
              <Text style={styles.address}>
                INVOICE #: ROTR_B{String(exhibitor.rotr_exhibitor_id).padStart(4, '0')}
              </Text>
              <Text style={styles.address}>INVOICE DATE: 02/17/2025</Text>
              <Text style={styles.address}>DUE DATE: 03/31/2025</Text>
            </View>
          </View>

          <View style={styles.customerInfo}>
            <Text style={styles.toLabel}>TO:</Text>
            <Text style={styles.customerText}>
              {exhibitor.first_name} {exhibitor.last_name}
            </Text>
            <Link src={`mailto:${exhibitor.payment_email}`} style={styles.customerText}>
              {exhibitor.payment_email}
            </Link>
            <Text style={styles.customerText}>{exhibitor.exhibitor_name}</Text>
          </View>

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableCell, { flex: 2, textAlign: 'left' }]}>DESCRIPTION</Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>QUANTITY</Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>UNIT PRICE</Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>TOTAL</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 2, textAlign: 'left' }]}>
                ROTR Booth: {exhibitor.booth_number} ({exhibitor.booth_type})
              </Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>1</Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                {convertValueToCurrency(exhibitor.booth_price)}
              </Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                {convertValueToCurrency(exhibitor.booth_price)}
              </Text>
            </View>
            {exhibitor.addons?.map((addon) => (
              <View key={addon.addon_id} style={styles.tableRow}>
                <Text style={[styles.tableCell, { flex: 2, textAlign: 'left' }]}>
                  {addon.addon_description}
                </Text>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                  {addon.addon_qty_selected}
                </Text>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                  {convertValueToCurrency(addon.addon_price)}
                </Text>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                  {convertValueToCurrency(addon.addon_qty_selected * Number(addon.addon_price))}
                </Text>
              </View>
            ))}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 2, textAlign: 'left' }]}>-</Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>-</Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>-</Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}>-</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 2, textAlign: 'left' }, styles.totalLabel]}>
                TOTAL
              </Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}></Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }]}></Text>
              <Text style={[styles.tableCell, { textAlign: 'right' }, styles.totalValue]}>
                {convertValueToCurrency(exhibitor.payment_total)}
              </Text>
            </View>
          </View>

          <View style={styles.footer}>
            <Text style={styles.footerText}>
              Credit memos should be sent to{' '}
              <Link src='mailto:lindsey.scott@sportssouth.biz' style={styles.footerText}>
                lindsey.scott@sportssouth.biz
              </Link>
              .
            </Text>
            <Text style={styles.footerText}>
              If you have any questions concerning this invoice, email{' '}
              <Link src='mailto:lindsey.scott@sportssouth.biz' style={styles.footerText}>
                lindsey.scott@sportssouth.biz
              </Link>
              .
            </Text>
            <Text style={styles.thankYou}>Thank you for your business!</Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PDFDocument;
